/* Login Page */
@font-face {
    font-family: duraregular-p;
    src: local('duraregular-p'),
      url('../fonts/dura-rep-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
  :root {
    --primary-blue: #1189e6;
    --white: #fff;
  }
  .text-stroke {
    line-height: 40px !important;
    text-transform: uppercase;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--primary-blue);
    color: var(--white);
  }
  .rounded1 {
    position: absolute;
    left: 35%;
    top: 10%;
  }
  .rounded2 {
    transform: scale(0.45);
    position: absolute;
    left: 67%;
  }
  

  
  